<template>
  <div class="examRule p-t-30">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="568px"
    >
      <div class="examRule_head flex">
        <span>调店</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="examRule_main p-t-30 p-r-40 p-b-30 p-l-40">
        <div class="borrow-form">
          <el-form
            ref="form"
            :rules="rules"
            class="form"
            :model="form"
            label-width="100px"
          >
            <el-form-item label="区域" prop="area">
              <el-cascader
                :options="regionList"
                v-model="region"
                placeholder="请选择"
                @change="handleChangeCity"
              >
              </el-cascader>
            </el-form-item>
            <el-form-item label="门店:" prop="dian_id">
              <el-select :disabled="!form.area" @change="getManagerList" v-model="form.dian_id" placeholder="请选择">
                <el-option
                  v-for="item in shopList"
                  :key="item.id"
                  :label="item.dian_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="经理:" prop="manager_id">
              <el-select :disabled="!form.area && form.dian_id" v-model="form.manager_id" placeholder="请选择">
                <el-option
                  v-for="item in managerList"
                  :key="item.user_id"
                  :label="item.name"
                  :value="item.user_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="interview_main_footer center">
            <el-button @click="submit('form')">提交</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data";
export default {
  data() {
    return {
      regionList: regionData,
      CodeToText: CodeToText,
      dialogVisible: false,
      region: [], //区域
      content: "", //规则
      form: {
        area:'',
        dian_id:'',
        manager_id:'',
        id:''
      },
      value: "",
      options: [],
      rules: {
        area: [
          { required: true, message: "请选择区域", trigger: "change" },
        ],
        dian_id: [
          { required: true, message: "请选择门店", trigger: "change" },
        ],
        manager_id: [
          { required: true, message: "请选择经理", trigger: "change" },
        ]
      },
      shopList:[],//门店列表
      managerList:[],//经理列表
    };
  },
  methods: {
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
         this.$axios.theShop(this.form).then(res=>{
           this.$message({
              type: "success",
              message: res.msg,
            });
            this.dialogVisible = false
            this.$emit('succeed')
         })
        } else {
          return false;
        }
      });
    },
    getManagerList () {
      this.$axios.userList({position:3,dian_id:this.form.dian_id,city:this.form.area}).then(res=>{
        this.managerList = res.data
      })
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //打开
    open(id) {
      this.form.id = id
      this.dialogVisible = true;
    },
    //获取门店
    getmenDian () {
      this.$axios.menDianC({
        city:this.form.area
      }).then(res=>{
        this.shopList = res.data
      })
    },
    //选择区域
    handleChangeCity() {
      let data = [];
      for (let i = 0; i < this.region.length; i++) {
        data.push(this.CodeToText[this.region[i]]);
      }
      this.form.dian_id = ''
      this.form.manager_id = ''
      this.form.area = data.join("/");
      this.getmenDian()
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-select,/deep/ .el-cascader {
  width: 100%;
}
/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}
.interview_main_footer {
  padding: 30px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
