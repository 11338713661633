<template>
  <div class="affairs_page">
    <div class="affairs-main">
      <div class="agree_pos">
        <router-link to="/personage/affairsStaff">员工管理</router-link>
        <span> > </span>
        <router-link class="color_blue" to=""> 详情</router-link>
      </div>
      <!--affairs-content start-->
      <div class="affairs-content">
        <div class="affairs-add">
          <!--add-hd start-->
          <div class="add-hd">
            <div class="hd-l">
              <img
                class="hd-img"
                src="../../assets/image/personage/other.png"
              />
              <div class="hd-name">李思雨</div>
            </div>
            <div class="hd-r">
              <el-button class="add-hd-btn blue">修改</el-button>
              <el-button class="add-hd-btn red">离职</el-button>
              <el-button class="add-hd-btn">调店</el-button>
            </div>
          </div>
          <!--add-hd end-->
          <div class="add-tit">个人信息</div>
          <!--add-item start-->
          <div class="add-item">
            <div class="item-li">
              <div class="label">姓名：</div>
              <div class="c">李思雨</div>
            </div>
            <div class="item-li">
              <div class="label">性别：</div>
              <div class="c">女</div>
            </div>
          </div>
          <!--add-item end-->
          <!--add-item start-->
          <div class="add-item">
            <div class="item-li">
              <div class="label">手机号：</div>
              <div class="c">15841568526</div>
            </div>
            <div class="item-li">
              <div class="label">身份证号：</div>
              <div class="c">410258965412586543</div>
            </div>
          </div>
          <!--add-item end-->
          <!--add-item start-->
          <div class="add-item">
            <div class="item-li">
              <div class="label">年龄：</div>
              <div class="c">23</div>
            </div>
            <div class="item-li">
              <div class="label">生日：</div>
              <div class="c">1998-08-08</div>
            </div>
          </div>
          <!--add-item end-->
          <!--add-item start-->
          <div class="add-item">
            <div class="item-li">
              <div class="label">现住：</div>
              <div class="c">河南省郑州市中原区电厂路华强广场</div>
            </div>
            <div class="item-li">
              <div class="label">籍贯：</div>
              <div class="c">河南省郑州市中原区电厂路望湖花园</div>
            </div>
          </div>
          <!--add-item end-->
          <!--add-item start-->
          <div class="add-item">
            <div class="item-li">
              <div class="label">直系亲属：</div>
              <div class="c">李思琪</div>
            </div>
            <div class="item-li">
              <div class="label">电话：</div>
              <div class="c">15215885402668</div>
            </div>
          </div>
          <!--add-item end-->
          <div class="add-tit">职位信息</div>
          <!--add-item start-->
          <div class="add-item">
            <div class="item-li">
              <div class="label">工号：</div>
              <div class="c">025988</div>
            </div>
            <div class="item-li">
              <div class="label">职位：</div>
              <div class="c">经纪人</div>
            </div>
          </div>
          <!--add-item end-->
          <!--add-item start-->
          <div class="add-item">
            <div class="item-li">
              <div class="label">门店：</div>
              <div class="c">华强广场店</div>
            </div>
            <div class="item-li">
              <div class="label">经理：</div>
              <div class="c">张某胡</div>
            </div>
          </div>
          <!--add-item end-->
          <!--add-item start-->
          <div class="add-item">
            <div class="item-li">
              <div class="label">入职时间：</div>
              <div class="c">2019-10-25</div>
            </div>
            <div class="item-li">
              <div class="label">薪资模式：</div>
              <div class="c">底薪+提成</div>
            </div>
          </div>
          <!--add-item end-->
          <!--add-item start-->
          <div class="add-item">
            <div class="item-li">
              <div class="label">在职状态：</div>
              <div class="c">在职</div>
            </div>
          </div>
          <!--add-item end-->
          <div class="add-tit">其他信息</div>
          <!--add-item start-->
          <div class="add-item">
            <div class="item-li">
              <div class="label w100">身份证正面：</div>
              <div class="c">
                <div class="id-img">
                  <img src="../../assets/image/personage/id.png" />
                </div>
              </div>
            </div>
            <div class="item-li">
              <div class="label w100">身份证反面：</div>
              <div class="c">
                <div class="id-img">
                  <img src="../../assets/image/personage/id.png" />
                </div>
              </div>
            </div>
          </div>
          <!--add-item end-->
          <!--add-item start-->
          <div class="add-item">
            <div class="label w100">劳动合同：</div>
            <div class="c">
              <div class="up-img">
                <img src="../../assets/image/personage/other.png" />
                <img src="../../assets/image/personage/other.png" />
                <img src="../../assets/image/personage/other.png" />
              </div>
            </div>
          </div>
          <!--add-item end-->
          <!--add-item start-->
          <div class="add-item">
            <div class="label w100">其他：</div>
            <div class="c">
              <div class="up-img">
                <img src="../../assets/image/personage/other.png" />
                <img src="../../assets/image/personage/other.png" />
                <img src="../../assets/image/personage/other.png" />
                <img src="../../assets/image/personage/other.png" />
              </div>
            </div>
          </div>
          <!--add-item end-->
          <div class="add-tit">登录信息</div>
          <!--add-item start-->
          <div class="add-item">
            <div class="item-li">
              <div class="label">登录账号：</div>
              <div class="c">124536987845</div>
            </div>
            <div class="item-li">
              <div class="label">登录密码：</div>
              <div class="c">124563</div>
            </div>
          </div>
          <!--add-item end-->
        </div>
      </div>
      <!--affairs-content end-->
      <!-- 调店弹框 S -->
      <staff-pop ref="staff"></staff-pop>
      <!--  调店弹框弹框 E -->
    </div>
  </div>
</template>

<script>
import StaffPop from "../../components/StaffPop";
export default {
  components: { StaffPop },
  data() {
    return {
      page: 1, //页数
      total: 0, //条数
      list: [], //列表
    };
  },
};
</script>

<style scoped lang="less">
.agree_pos {
  padding: 30px 30px;
  color: #666;
}
.agree_a {
  color: #3273f6;
  cursor: pointer;
}
/deep/.key_page_select .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 160px;
}
.agree_main {
  background: #fff;
  padding-bottom: 30px;
}
.agree-head {
  margin: 0 20px;
  padding: 20px 0px;
  position: relative;
}
.affairs-main {
  background: #fff;
  padding-bottom: 30px;
}
.affairs-head {
}
.affairs-add-btn {
  width: 100px;
  height: 40px;
  padding: 0px;
  border-radius: 4px;
  background: #3273f6;
  text-align: center;
  color: #fff;
  line-height: 40px;
  font-size: 16px;
  position: absolute;
  top: 30px;
  right: 0px;
}
.affairs-content {
  padding: 0 20px;
}
.affairs-add {
  padding: 0 10px;
  .add-hd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .hd-l {
      display: flex;
    }
    .hd-img {
      width: 64px;
      height: 64px;
      border-radius: 100%;
    }
    .hd-name {
      font-size: 18px;
      margin-left: 20px;
    }
    .add-hd-btn {
      width: 80px;
      height: 36px;
      border-radius: 4px;
      line-height: 36px;
      text-align: center;
      font-size: 16px;
      border: 1px solid #3273f6;
      color: #3273f6;
      margin-left: 15px;
      padding: 0px;
      background: #fff;
    }
    .add-hd-btn.red {
      border: 1px solid #fddfdf;
      background: #fddfdf;
      color: #ff5151;
    }
    .add-hd-btn.blue {
      background: #3273f6;
      color: #fff;
    }
  }
  .add-tit {
    font-size: 18px;
    position: relative;
    padding-left: 10px;
    margin: 25px 0px 10px 0px;
  }
  .add-tit:before {
    position: absolute;
    left: 0px;
    top: 50%;
    margin-top: -10px;
    width: 4px;
    height: 20px;
    background: #3273f6;
    display: block;
    content: "";
  }
  .add-item {
    display: flex;
    margin: 0 20px;
    font-size: 16px;
    margin: 15px 15px;
  }
  .item-li {
    width: 50%;
    display: flex;
    line-height: 1.6;
  }
  /deep/ .el-input {
    width: 300px;
  }
  /deep/ .el-form-item__label {
    font-size: 16px;
  }
  /deep/ .el-input__inner {
    font-size: 16px;
    border-radius: 0;
    border: 1px solid #ccc;
  }
  .add-item-icon {
    margin: 8px 10px;
  }
  .add-item-icon img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .label {
    color: #999;
    white-space: nowrap;
    line-height: 1.6;
  }
  .c {
    line-height: 1.6;
  }
  .w100 {
    width: 100px;
    text-align: right;
  }
  .id-img img {
    width: 180px;
    height: 120px;
    border-radius: 4px;
  }
  .up-img {
    display: flex;
    flex-wrap: wrap;
  }
  .up-img img {
    width: 120px;
    height: 120px;
    border-radius: 4px;
    margin: 0 20px 10px 0px;
  }
}
</style>
